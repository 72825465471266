import { COMPANY_TABLE_PAGE_SIZE_KEY } from "../../../utils/constants";

import * as actionTypes from "../actionTypes";
import { updateState } from "../utils";

const initialState = {
    tickets: null,
    total: 0,
    filter: {
        page: 1,
        pageSize: Number(localStorage.getItem(COMPANY_TABLE_PAGE_SIZE_KEY) || 10),
        searchKey: "",
        commune: null,
        storeType: null,
        status: null,
        type: null,
    },
};

const updateTickets = (state, action) => {
    return updateState(
        state,
        {
            tickets: action.tickets,
            total: action.total,
        }
    );
};

const updateFilter = (state, action) => {
    return updateState(
        state,
        {
            filter: action.filter,
        }
    );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_TICKETS: return updateTickets(state, action);
    case actionTypes.UPDATE_TICKET_FILTER: return updateFilter(state, action);
    default: return state;
    }
};

export default reducer;
