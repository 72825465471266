import * as actionTypes from "../actionTypes";
import { updateState } from "../utils";

const initialState = {
    permissions: null,
    groups: null,
    total: 0,
    filter: {
        page: 1,
        pageSize: 10,
    },
};

const updatePermissions = (state, action) => {
    return updateState(state, {
        permissions: action.permissions,
    });
};

const updateGroups = (state, action) => {
    return updateState(state, {
        groups: action.groups,
        total: action.total,
    });
};

const updateFilter = (state, action) => {
    return updateState(state, {
        filter: action.filter,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PERMISSIONS:
            return updatePermissions(state, action);
        case actionTypes.UPDATE_GROUPS:
            return updateGroups(state, action);
        case actionTypes.UPDATE_GROUP_FILTER:
            return updateFilter(state, action);
        default:
            return state;
    }
};

export default reducer;
