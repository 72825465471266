import { combineReducers } from "redux";

import theme from "./theme";
import data from "./data";
import auth from "./auth";
import user from "./user";
import company from "./company";
import product from "./product";
import store from "./store";
import report from "./report";
import ticket from "./ticket";
import system from "./system";

export default combineReducers({
    theme,
    data,
    auth,
    user,
    company,
    product,
    store,
    report,
    ticket,
    system,
});
