import React from "react";
import PropTypes from "prop-types";

import { Pagination } from "antd";

const props = {
    total: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    pageSize: PropTypes.number,
    onPageSizeChange: PropTypes.func,
    className: PropTypes.string,
    pageSizeKey: PropTypes.string,
};

const LSPagination = ({ total, page, onChange, pageSize, className, pageSizeKey }) => {

    return (
        <div className={`d-flex justify-content-end align-items-center ${className || ""}`}>
            <Pagination
                total={total}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                pageSize={pageSize || 10}
                current={page}
                responsive
                showSizeChanger
                onShowSizeChange={(_, size) => {
                    if (pageSizeKey) {
                        localStorage.setItem(pageSizeKey, size);
                    }
                }}
                onChange={(page, pageSize) => onChange(page, pageSize)}
            />
        </div>
    );
};

LSPagination.propTypes = props;

export default LSPagination;
