import { COMPANY_TABLE_PAGE_SIZE_KEY } from "../../../utils/constants";

import * as actionTypes from "../actionTypes";
import { updateState } from "../utils";

const initialState = {
    allCompanies: null,
    companies: null,
    total: 0,
    filter: {
        page: 1,
        pageSize: Number(localStorage.getItem(COMPANY_TABLE_PAGE_SIZE_KEY) || 10),
        searchKey: "",
        commune: null,
        status: null,
        sortKey: "created_at",
        sortDirection: "desc",
    },
    plans: null,
};

const updateAllCompanies = (state, action) => {
    return updateState(
        state,
        {
            allCompanies: action.allCompanies,
        }
    );
};

const updateCompanies = (state, action) => {
    return updateState(
        state,
        {
            companies: action.companies,
            total: action.total,
        }
    );
};

const updateFilter = (state, action) => {
    return updateState(
        state,
        {
            filter: action.filter,
        }
    );
};

const updatePlans = (state, action) => {
    return updateState(
        state,
        {
            plans: action.plans,
        }
    );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_ALL_COMPANIES: return updateAllCompanies(state, action);
    case actionTypes.UPDATE_COMPANIES: return updateCompanies(state, action);
    case actionTypes.UPDATE_COMPANY_FILTER: return updateFilter(state, action);
    case actionTypes.UPDATE_PLANS: return updatePlans(state, action);
    default: return state;
    }
};

export default reducer;
