import { theme } from "antd";

import {
    lsColorBlack,
    lsColorDark,
    lsColorError,
    lsColorGray,
    lsColorPrimary,
    lsColorSuccess,
    lsColorWhite,
} from "./constants";

const antdTheme = {
    algorithm: theme.defaultAlgorithm,
    token: {
        colorPrimary: lsColorPrimary,
        colorLink: lsColorPrimary,
        colorBgLayout: lsColorWhite,
        colorTextBase: lsColorDark,
        colorText: lsColorDark,

        borderRadius: 6,

        controlHeight: 36,

        fontFamily: "Roboto",
    },
};

const styledTheme = {
    colorPrimary: lsColorPrimary,
    colorRed: lsColorError,
    colorOrange: lsColorPrimary,
    colorPurple: lsColorSuccess,

    colorWhite: lsColorWhite,
    colorGray: lsColorGray,
    colorDark: lsColorDark,
    colorBlack: lsColorBlack,

    antdBorderColor: "#d9d9d9",
    antdInputHoverBorderColor: "#79a1a6",
    antdInputFocusBoxShadow: "rgb(55 5 255 / 6%)",
    antdInputColor: "rgba(0, 0, 0, 0.88)",

    // Component colors
    authBgColor: "#FFF",
    authLeftBgColor: "#13276D",
    siderBgColor: lsColorGray,
    siderMenuActiveColor: lsColorPrimary,
    siderMenuTextColor: "#5a5a5a",
};

export {
    antdTheme,
    styledTheme,
};
