import { COMMUNE_TABLE_PAGE_SIZE_KEY } from "../../../utils/constants";
import * as actionTypes from "../actionTypes";
import { updateState } from "../utils";

const initialState = {
    roles: null,

    countries: null,

    regions: null,

    provinces: null,

    allCommunes: null,
    communes: null,
    communeTotal: 0,
    communeFilter: {
        page: 1,
        pageSize: Number(localStorage.getItem(COMMUNE_TABLE_PAGE_SIZE_KEY) || 10),
        searchKey: "",
        province: null,
        region: null,
    },

    terms: null,

    deliveryCosts: null,
};

const updateRoles = (state, action) => {
    return updateState(state, {
        roles: action.roles,
    });
};

const updateCountries = (state, action) => {
    return updateState(state, {
        countries: action.countries,
    });
};

const updateRegions = (state, action) => {
    return updateState(state, {
        regions: action.regions,
    });
};

const updateProvinces = (state, action) => {
    return updateState(state, {
        provinces: action.provinces,
    });
};

const updateAllCommunes = (state, action) => {
    return updateState(state, {
        allCommunes: action.allCommunes,
    });
};

const updateCommunes = (state, action) => {
    return updateState(state, {
        communes: action.communes,
        communeTotal: action.communeTotal,
    });
};

const updateCommuneFilter = (state, action) => {
    return updateState(state, {
        communeFilter: action.communeFilter,
    });
};

const updateTerms = (state, action) => {
    return updateState(state, {
        terms: action.terms,
    });
};

const updateDeliveryCosts = (state, action) => {
    return updateState(state, {
        deliveryCosts: action.deliveryCosts,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_ROLES:
            return updateRoles(state, action);
        case actionTypes.UPDATE_COUNTRIES:
            return updateCountries(state, action);
        case actionTypes.UPDATE_REGIONS:
            return updateRegions(state, action);
        case actionTypes.UPDATE_PROVINCES:
            return updateProvinces(state, action);
        case actionTypes.UPDATE_ALL_COMMUNES:
            return updateAllCommunes(state, action);
        case actionTypes.UPDATE_COMMUNES:
            return updateCommunes(state, action);
        case actionTypes.UPDATE_COMMUNE_FILTER:
            return updateCommuneFilter(state, action);
        case actionTypes.UPDATE_TERMS:
            return updateTerms(state, action);
        case actionTypes.UPDATE_DELIVERY_COSTS:
            return updateDeliveryCosts(state, action);
        default:
            return state;
    }
};

export default reducer;
