export const UPDATE_SIDER_COLLAPSED = "UPDATE_SIDER_COLLAPSED";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";

export const UPDATE_ROLES = "UPDATE_ROLES";
export const UPDATE_COUNTRIES = "UPDATE_COUNTRIES";
export const UPDATE_REGIONS = "UPDATE_REGIONS";
export const UPDATE_PROVINCES = "UPDATE_PROVINCES";
export const UPDATE_ALL_COMMUNES = "UPDATE_ALL_COMMUNES";
export const UPDATE_COMMUNES = "UPDATE_COMMUNES";
export const UPDATE_COMMUNE_FILTER = "UPDATE_COMMUNE_FILTER";
export const UPDATE_TERMS = "UPDATE_TERMS";
export const UPDATE_DELIVERY_COSTS = "UPDATE_DELIVERY_COSTS";

export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_SOCKET_TOKEN = "UPDATE_SOCKET_TOKEN";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_POSEIDON_USERS = "UPDATE_POSEIDON_USERS";

export const UPDATE_ALL_USERS = "UPDATE_ALL_USERS";
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_USER_FILTER = "UPDATE_USER_FILTER";

export const UPDATE_ALL_COMPANIES = "UPDATE_ALL_COMPANIES";
export const UPDATE_COMPANIES = "UPDATE_COMPANIES";
export const UPDATE_COMPANY_FILTER = "UPDATE_COMPANY_FILTER";
export const UPDATE_PLANS = "UPDATE_PLANS";

export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const UPDATE_PRODUCT_FILTER = "UPDATE_PRODUCT_FILTER";
export const UPDATE_PRODUCT_CLASSES = "UPDATE_PRODUCT_CLASSES";
export const UPDATE_PRODUCT_NAMES = "UPDATE_PRODUCT_NAMES";
export const UPDATE_PRODUCT_NAME_FILTER = "UPDATE_PRODUCT_NAME_FILTER";
export const UPDATE_PRODUCT_SUGGESTIONS = "UPDATE_PRODUCT_SUGGESTIONS";
export const UPDATE_PRODUCT_SUGGESTION_FILTER = "UPDATE_PRODUCT_SUGGESTION_FILTER";
export const UPDATE_BRANDS = "UPDATE_BRANDS";
export const UPDATE_BRAND_FILTER = "UPDATE_BRAND_FILTER";
export const UPDATE_PACKAGES = "UPDATE_PACKAGES";
export const UPDATE_MEASURE_UNITS = "UPDATE_MEASURE_UNITS";
export const UPDATE_ENTERPRISES = "UPDATE_ENTERPRISES";

export const UPDATE_ALL_STORES = "UPDATE_ALL_STORES";
export const UPDATE_STORES = "UPDATE_STORES";
export const UPDATE_STORE_FILTER = "UPDATE_STORE_FILTER";
export const UPDATE_STORE_TYPES = "UPDATE_STORE_TYPES";

export const UPDATE_PRODUCT_CATEGORIES = "UPDATE_PRODUCT_CATEGORIES";
export const UPDATE_PRODUCT_REPORT_NAMES = "UPDATE_PRODUCT_REPORT_NAMES";
export const UPDATE_PRODUCT_REPORT_NAME_FILTER = "UPDATE_PRODUCT_REPORT_NAME_FILTER";
export const UPDATE_ATTRIBUTES = "UPDATE_ATTRIBUTES";

export const UPDATE_TICKETS = "UPDATE_TICKETS";
export const UPDATE_TICKET_FILTER = "UPDATE_TICKET_FILTER";

export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";
export const UPDATE_GROUPS = "UPDATE_GROUPS";
export const UPDATE_GROUP_FILTER = "UPDATE_GROUP_FILTER";
