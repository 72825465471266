import styled from "styled-components";
import { rgba } from "polished";

import AngleUpIcon from "../../../assets/images/icons/chevron-up.svg";
import AngleDownIcon from "../../../assets/images/icons/chevron-down.svg";

export const LSTableV1 = styled.table`
    width: 100%;

    border-collapse: collapse;
    border-radius: 6px;

    overflow: hidden;

    box-shadow: ${(props) => `0 5px 20px ${rgba(props.theme.colorBlack, .08)}`};

    thead {
        tr {
            th {
                padding: 10px 12px;

                text-align: left;
                vertical-align: middle;

                font-size: 15px;
                color: ${(props) => props.theme.colorDark} !important;

                background-color: ${(props) => rgba(props.theme.colorGray, 0.1)};

                &.has-sort {
                    span {
                        width: 100%;

                        display: block;
                        position: relative;

                        border: none;
                        cursor: pointer;

                        padding-right: 16px;

                        &::before {
                            content: '';
                            width: 10px;
                            height: 10px;

                            position: absolute;
                            top: calc(50% - 10px);
                            right: 0;
                            
                            background-image: url(${AngleUpIcon});
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;

                            transition: .2s all ease-in-out;
                            opacity: .4;
                        }

                        &::after {
                            content: '';
                            width: 10px;
                            height: 10px;

                            position: absolute;
                            top: calc(50% + 2px);
                            right: 0;
                            
                            background-image: url(${AngleDownIcon});
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;

                            transition: .2s all ease-in-out;
                            opacity: .4;
                        }
                    }
                    &.asc {
                        span {
                            &::before {
                                opacity: 1;
                            }
                        }
                    }
                    &.desc {
                        span {
                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    tbody {
        tr {
            &:nth-child(even) {
                td {
                    background-color: ${(props) => rgba(props.theme.colorGray, .1)};
                }
            }

            td {
                padding: 10px 12px;

                vertical-align: middle;

                color: ${(props) => props.theme.colorDark};
            }
        }
    }
`;