import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Loader from "./Loader";

const ThemeLoader = () => {

    const { loading } = useSelector((state) => state.theme);

    return loading ?
        (
            <Wrapper>
                <Loader />
            </Wrapper>
        )
        :
        null;
};

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-color: rgba(0, 0, 0, .75);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 9999;
`;

export default ThemeLoader;
