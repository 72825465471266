import React from "react";
import ReactDOM from "react-dom/client";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";

import reportWebVitals from "./reportWebVitals";

import App from "./App";

import rootReducer from "./services/store/reducers";

import "./assets/styles/styles.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

reportWebVitals();
