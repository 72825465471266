import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Skeleton } from "antd";

const props = {
    rows: PropTypes.number.isRequired,
    cols: PropTypes.number.isRequired,
};

const LSTableLoader = ({ rows, cols }) => {

    const [rowArray, setRowArray] = useState([]);
    const [colArray, setColArray] = useState([]);

    useEffect(() => {
        let rowArray = [];
        for (let i = 0; i < rows; i++) rowArray.push(i);
        setRowArray(rowArray);
    }, [rows]);

    useEffect(() => {
        let colArray = [];
        for (let i = 0; i < cols; i++) colArray.push(i);
        setColArray(colArray);
    }, [cols]);

    return (
        <React.Fragment>
            {
                rowArray.map(rowIndex => (
                    <tr key={rowIndex}>
                        {
                            colArray.map(colIndex => (
                                <td key={`${rowIndex}_${colIndex}`}>
                                    <Skeleton active paragraph={{ rows: 1 }} title={false} />
                                </td>
                            ))
                        }
                    </tr>
                ))
            }
        </React.Fragment>
    );
};

LSTableLoader.propTypes = props;

export default LSTableLoader;
