import React from "react";
import PropTypes from "prop-types";

import { Skeleton } from "antd";

const props = {
    rows: PropTypes.number.isRequired,
    title: PropTypes.bool,
};

const WrapperLoader = ({ rows, title }) => {

    return (
        <Skeleton active paragraph={{rows: rows}} title={title} />
    );
};

WrapperLoader.propTypes = props;

export default WrapperLoader;
