import { USER_TABLE_PAGE_SIZE_KEY } from "../../../utils/constants";

import * as actionTypes from "../actionTypes";
import { updateState } from "../utils";

const initialState = {
    allUsers: null,
    users: null,
    total: 0,
    filter: {
        page: 1,
        pageSize: Number(localStorage.getItem(USER_TABLE_PAGE_SIZE_KEY) || 10),
        searchKey: "",
        gender: null,
        status: null,
    },
};

const updateAllUsers = (state, action) => {
    return updateState(
        state,
        {
            allUsers: action.allUsers,
        }
    );
};

const updateUsers = (state, action) => {
    return updateState(
        state,
        {
            users: action.users,
            total: action.total,
        }
    );
};

const updateFilter = (state, action) => {
    return updateState(
        state,
        {
            filter: action.filter,
        }
    );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_ALL_USERS: return updateAllUsers(state, action);
    case actionTypes.UPDATE_USERS: return updateUsers(state, action);
    case actionTypes.UPDATE_USER_FILTER: return updateFilter(state, action);
    default: return state;
    }
};

export default reducer;
