import { PRODUCT_REPORT_NAME_TABLE_PAGE_SIZE_KEY } from "../../../utils/constants";

import * as actionTypes from "../actionTypes";
import { updateState } from "../utils";

const initialState = {
    productCategories: null,

    productReportNames: null,
    productReportNameTotal: 0,
    productReportNameFilter: {
        page: 1,
        pageSize: Number(localStorage.getItem(PRODUCT_REPORT_NAME_TABLE_PAGE_SIZE_KEY) || 10),
        searchKey: "",
    },

    attributes: null,
};

const updateProductCategories = (state, action) => {
    return updateState(state, {
        productCategories: action.productCategories,
    });
};

const updateProductReportNames = (state, action) => {
    return updateState(state, {
        productReportNames: action.productReportNames,
        productReportNameTotal: action.productReportNameTotal,
    });
};

const updateProductReportNameFilter = (state, action) => {
    return updateState(state, {
        productReportNameFilter: action.productReportNameFilter,
    });
};

const updateAttributes = (state, action) => {
    return updateState(state, {
        attributes: action.attributes,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PRODUCT_CATEGORIES:
            return updateProductCategories(state, action);
        case actionTypes.UPDATE_PRODUCT_REPORT_NAMES:
            return updateProductReportNames(state, action);
        case actionTypes.UPDATE_PRODUCT_REPORT_NAME_FILTER:
            return updateProductReportNameFilter(state, action);
        case actionTypes.UPDATE_ATTRIBUTES:
            return updateAttributes(state, action);
        default:
            return state;
    }
};

export default reducer;
