import { STORE_TABLE_PAGE_SIZE_KEY } from "../../../utils/constants";

import * as actionTypes from "../actionTypes";
import { updateState } from "../utils";

const initialState = {
    allStores: null,
    stores: null,
    total: 0,
    filter: {
        page: 1,
        pageSize: Number(localStorage.getItem(STORE_TABLE_PAGE_SIZE_KEY) || 10),
        searchKey: "",
        storeType: null,
        company: null,
        commune: null,
        startDate: null,
        finishDate: null,
        status: null,
        sortKey: "created_at",
        sortDirection: "desc",
    },
    storeTypes: null,
};

const updateAllStores = (state, action) => {
    return updateState(
        state,
        {
            allStores: action.allStores,
        }
    );
};

const updateStores = (state, action) => {
    return updateState(
        state,
        {
            stores: action.stores,
            total: action.total,
        }
    );
};

const updateFilter = (state, action) => {
    return updateState(
        state,
        {
            filter: action.filter,
        }
    );
};

const updateStoreTypes = (state, action) => {
    return updateState(
        state,
        {
            storeTypes: action.storeTypes,
        }
    );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_ALL_STORES: return updateAllStores(state, action);
    case actionTypes.UPDATE_STORES: return updateStores(state, action);
    case actionTypes.UPDATE_STORE_FILTER: return updateFilter(state, action);
    case actionTypes.UPDATE_STORE_TYPES: return updateStoreTypes(state, action);
    default: return state;
    }
};

export default reducer;
