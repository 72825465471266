/** ------------------- */
// ENV VARIABLES
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;

export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
export const AWS_REGION_NAME = process.env.REACT_APP_AWS_REGION_NAME;
export const AWS_S3_BUCKET_NAME = process.env.REACT_APP_AWS_S3_BUCKET_NAME;

/** ------------------- */
// COLORS
export const lsColorWhite = "#FFF";
export const lsColorGray = "#707070";
export const lsColorDark = "#212121";
export const lsColorBlack = "#000";

export const lsColorError = "#F44336";
export const lsColorWarning = "#F44336";
export const lsColorSuccess = "#0CA034";
export const lsColorPrimary = "#EB6F00";

/** ------------------- */
// LOCALSTORAGE KEYS
export const LS_VERIFY_EMAIL_KEY = "verify_email";
export const LS_AUTH_TOKEN_KEY = "auth_token";
export const USER_TABLE_PAGE_SIZE_KEY = "user_table_page_size";
export const COMMUNE_TABLE_PAGE_SIZE_KEY = "commune_table_page_size";
export const COMPANY_TABLE_PAGE_SIZE_KEY = "company_table_page_size";
export const PRODUCT_TABLE_PAGE_SIZE_KEY = "product_table_page_size";
export const PRODUCT_NAME_TABLE_PAGE_SIZE_KEY = "product_name_table_page_size";
export const PRODUCT_SUGGESTION_TABLE_PAGE_SIZE_KEY = "product_suggestion_table_page_size";
export const PRODUCT_REPORT_NAME_TABLE_PAGE_SIZE_KEY = "product_report_name_table_page_size";
export const BRAND_TABLE_PAGE_SIZE_KEY = "brand_table_page_size";
export const STORE_TABLE_PAGE_SIZE_KEY = "store_table_page_size";
export const TICKET_TABLE_PAGE_SIZE_KEY = "ticket_table_page_size";

/** ------------------- */
// STRING MESSAGES
export const msgFieldRequired = "This field is required";
export const msgFieldInvalid = "This field is invalid";
export const msgConfirmPasswordCorrectly = "Confirm password correctly";

/** ------------------- */
// SYSTEM CONSTANTS
