import dayjs from "dayjs";

export const getUserName = (userData) => {
    if (!userData) {
        return "";
    }
    let userName = "";
    const { first_name, last_name, email, user_id } = userData;
    if (first_name || last_name) {
        if (first_name) {
            userName += first_name;
        }
        if (last_name) {
            userName += (userName ? ` ${last_name}` : last_name);
        }
    }
    if (userName) {
        return userName;
    }
    if (email) {
        userName += email;
    }
    if (userName) {
        return userName;
    }
    return user_id;
};

export const isAdmin = (userData) => {
    if (!userData) {
        return false;
    }
    const { user_roles } = userData;
    const adminRole = user_roles.find((user_role) => user_role.role === 1 && user_role.accepted);
    return Boolean(adminRole);
};

export const getErrorMessage = (e) => {
    if (e.message === "Network Error") {
        return e.message;
    } else {
        if (e.response) {
            if (e.response.status === 401) {
                return "You are not authenticated, please login again!";
            } else if (e.response.status === 403) {
                return "You have no permission!";
            } else if (e.response.status === 404) {
                return "URL not found";
            } else if (e.response.status === 405) {
                return "Bad request";
            } else if (e.response.status === 500) {
                return "API error";
            } else {
                if (e.response?.data?.message) {
                    return e.response.data.message;
                } else {
                    return "Bad request";
                }
            }
        } else {
            return "API error";
        }
    }
};

export const formatDateTime = (datetime, formatter = "DD/MM/YYYY HH:mm") => {
    if (!datetime) return "";
    return dayjs(datetime).format(formatter);
};

export const filterTextHandler = (text, searchKey) => {
    if (!text) return false;
    return text
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .indexOf(searchKey.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) !== -1;
};

export const filterSelectOptionHandler = (input, option) => filterTextHandler(option.children, input);

export const getExtensionFromFileName = (fileName) => {
    if (!fileName) return "";
    const fileNameArr = fileName.split(".");
    return fileNameArr[fileNameArr.length - 1];
};

export const getSubscriptionStatus = (subscription) => {
    if (!subscription) return "incoming";
    if (subscription.deleted) return "suspended";
    if (!subscription.last_transaction_date) return "delayed";
    return "subscribed";
};
