import {
    BRAND_TABLE_PAGE_SIZE_KEY,
    PRODUCT_NAME_TABLE_PAGE_SIZE_KEY,
    PRODUCT_SUGGESTION_TABLE_PAGE_SIZE_KEY,
    PRODUCT_TABLE_PAGE_SIZE_KEY,
} from "../../../utils/constants";

import * as actionTypes from "../actionTypes";
import { updateState } from "../utils";

const initialState = {
    products: null,
    total: 0,
    filter: {
        page: 1,
        pageSize: Number(localStorage.getItem(PRODUCT_TABLE_PAGE_SIZE_KEY) || 10),
        searchKey: "",
        category: null,
        productClass: null,
        status: null,
        deleted: null,
        src: null,
        sortKey: "created_at",
        sortDirection: "desc",
    },

    productClasses: null,

    productNames: null,
    productNameTotal: 0,
    productNameFilter: {
        page: 1,
        pageSize: Number(localStorage.getItem(PRODUCT_NAME_TABLE_PAGE_SIZE_KEY) || 10),
        searchKey: "",
    },

    productSuggestions: null,
    productSuggestionTotal: 0,
    productSuggestionFilter: {
        page: 1,
        pageSize: Number(localStorage.getItem(PRODUCT_SUGGESTION_TABLE_PAGE_SIZE_KEY) || 10),
        status: null,
    },

    brands: null,
    brandTotal: 0,
    brandFilter: {
        page: 1,
        pageSize: Number(localStorage.getItem(BRAND_TABLE_PAGE_SIZE_KEY) || 10),
        searchKey: "",
    },

    packages: null,

    measureUnits: null,

    enterprises: null,
};

const updateProducts = (state, action) => {
    return updateState(state, {
        products: action.products,
        total: action.total,
    });
};

const updateFilter = (state, action) => {
    return updateState(state, {
        filter: action.filter,
    });
};

const updateProductClasses = (state, action) => {
    return updateState(state, {
        productClasses: action.productClasses,
    });
};

const updateProductNames = (state, action) => {
    return updateState(state, {
        productNames: action.productNames,
        productNameTotal: action.productNameTotal,
    });
};

const updateProductNameFilter = (state, action) => {
    return updateState(state, {
        productNameFilter: action.productNameFilter,
    });
};

const updateProductSuggestions = (state, action) => {
    return updateState(state, {
        productSuggestions: action.productSuggestions,
        productSuggestionTotal: action.productSuggestionTotal,
    });
};

const updateProductSuggestionFilter = (state, action) => {
    return updateState(state, {
        productSuggestionFilter: action.productSuggestionFilter,
    });
};

const updateBrands = (state, action) => {
    return updateState(state, {
        brands: action.brands,
        brandTotal: action.brandTotal,
    });
};

const updateBrandFilter = (state, action) => {
    return updateState(state, {
        brandFilter: action.brandFilter,
    });
};

const updatePackages = (state, action) => {
    return updateState(state, {
        packages: action.packages,
    });
};

const updateMeasureUnits = (state, action) => {
    return updateState(state, {
        measureUnits: action.measureUnits,
    });
};

const updateEnterprises = (state, action) => {
    return updateState(state, {
        enterprises: action.enterprises,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PRODUCTS:
            return updateProducts(state, action);
        case actionTypes.UPDATE_PRODUCT_FILTER:
            return updateFilter(state, action);
        case actionTypes.UPDATE_PRODUCT_CLASSES:
            return updateProductClasses(state, action);
        case actionTypes.UPDATE_PRODUCT_NAMES:
            return updateProductNames(state, action);
        case actionTypes.UPDATE_PRODUCT_NAME_FILTER:
            return updateProductNameFilter(state, action);
        case actionTypes.UPDATE_PRODUCT_SUGGESTIONS:
            return updateProductSuggestions(state, action);
        case actionTypes.UPDATE_PRODUCT_SUGGESTION_FILTER:
            return updateProductSuggestionFilter(state, action);
        case actionTypes.UPDATE_BRANDS:
            return updateBrands(state, action);
        case actionTypes.UPDATE_BRAND_FILTER:
            return updateBrandFilter(state, action);
        case actionTypes.UPDATE_PACKAGES:
            return updatePackages(state, action);
        case actionTypes.UPDATE_MEASURE_UNITS:
            return updateMeasureUnits(state, action);
        case actionTypes.UPDATE_ENTERPRISES:
            return updateEnterprises(state, action);
        default:
            return state;
    }
};

export default reducer;
